import styled from "styled-components";
import { ReactComponent as CornerStroke } from "assets/svg/corner-stroke.svg";

const LowerCornerStroke = styled(CornerStroke)`
  position: absolute;
  bottom: -24%;
  right: -6%;
  width: 36px;
  transform: translateY(-50%) rotate(180deg);
  z-index: -1;

  path {
    stroke-width: 8px;
  }

  ${({theme}) => theme.media.min.tablet`
    bottom: -16%;
  `}

  ${({theme}) => theme.media.min.laptop`
    bottom: -60px;
    right: -36px;
    width: 48px;
  `}
`;

export default LowerCornerStroke;
