import styled from "styled-components";
import { ReactComponent as CornerStroke } from "assets/svg/corner-stroke.svg";

const UpperCornerStroke = styled(CornerStroke)`
  position: absolute;
  top: -12%;
  left: -6%;
  width: 36px;
  //transform: translate(-50%, -50%);
  z-index: -1;

  path {
    stroke: ${({ theme }) => theme.colors.light};
    stroke-width: 7px;
  }

  ${({theme}) => theme.media.min.laptop`
    top: -32px;
    left: -32px;
    width: 48px;
  `}
`;

export default UpperCornerStroke;
