import styled from "styled-components";
import { ReactComponent as ZigZagSvg } from "assets/svg/zigzag.svg";

const ZigZag = styled(ZigZagSvg)`
  position: absolute;
  right: 4%;
  top: -8%;
  width: 48px;
  z-index: -1;

  ${({theme}) => theme.media.min.laptop`
    right: 4%;
    top: -4%;
    width: 56px;
  `}
`;

export default ZigZag;
