import styled from "styled-components";

const Header = styled.h1<{ light?: boolean }>`
  margin: 0;
  text-align: center;
  font-size: clamp(1.5rem, 5vw, 2.5rem);
  font-weight: ${({ theme }) => theme.fontWeights.bold};

  color: ${({ theme, light }) => light ? theme.colors.light : theme.colors.mainMid};

  ${({ theme, light }) => !light && `
    background: -webkit-linear-gradient(${theme.colors.mainLight}, ${theme.colors.mainDark});
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  `}
`;

export default Header;
