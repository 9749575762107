import React from "react";
import { ThemeProvider } from "styled-components";
import SinglePageWrapper from "components/SinglePageWrapper/SinglePageWrapper";
import styled from "styled-components";
import Header from "components/SinglePageWrapper/Header";
import Paragraph from "components/Paragraph";
import GlobalStyle from "styles/GlobalStyle";
import theme from "styles/theme";
import Helmet from "components/Helmet";

const StyledParagraph = styled(Paragraph)`
  text-align: center;
  margin: 24px auto;
  font-size: 1.5rem;
`;

const NotFoundPage: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet customTitle="Nie odnaleziono strony - Agencja FollowUP" />
      <GlobalStyle />
      <SinglePageWrapper centered>
        <div>
          <Header>Nie odnaleziono strony.</Header>
          <StyledParagraph colored>
            Pod podanym adresem nie znajduje się żadna strona.
          </StyledParagraph>
        </div>
      </SinglePageWrapper>
    </ThemeProvider>
  );
};

export default NotFoundPage;
