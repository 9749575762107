import styled from "styled-components";
import { ReactComponent as ThickX } from "assets/svg/thick-x.svg";

const Cross = styled(ThickX)`
  position: absolute;
  right: -5%;
  top: 35%;
  width: 16px;
  transform: rotate(22deg);
  z-index: -1;

  path {
    fill: ${({theme}) => theme.colors.light};
  }

  ${({theme}) => theme.media.min.tablet`
    top: 24%;
    right: -3%;
    width: 28px;
  `}
`;

export default Cross;
