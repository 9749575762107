import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import LowerCornerStroke from "./visual/LowerCornerStroke";
import UpperCornerStroke from "./visual/UpperCornerStroke";
import Cross from "./visual/Cross";
import { StyledProps } from "types/StyledProps";
import ZigZag from "./visual/ZigZag";
import DotsGrid from "./visual/DotsGrid";

const MainElementWrapper: React.FC<PropsWithChildren<StyledProps>> = (
  props: PropsWithChildren<StyledProps>
) => {
  return (
    <div className={props.className}>
      <UpperCornerStroke />
      {props.children}
      <LowerCornerStroke />
      <Cross />
      <ZigZag />
      <DotsGrid />
    </div>
  );
};

const StyledMainElementWrapper = styled(MainElementWrapper)`
  position: relative;
  margin: 48px 24px;
  margin-bottom: 96px;

  ${({theme}) => theme.media.min.tablet`
    width: 75%;
    max-width: 800px;
    margin: 48px auto;
    margin-bottom: 96px;
  `}
`;

export default StyledMainElementWrapper;
