import styled from "styled-components";
import { ReactComponent as DotsGridSvg } from "assets/svg/dots-grid.svg";

const DotsGrid = styled(DotsGridSvg)`
  position: absolute;
  left: -4%;
  bottom: -24%;
  width: 96px;
  z-index: -1;

  ${({theme}) => theme.media.min.tablet`
    bottom: -8%;
    width: 128px;
  `}
`;

export default DotsGrid;