import styled from "styled-components";

const TopBlob = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  height: 35vh;
  width: 200%;
  transform: translateX(-50%) translateY(-25%);
  background: ${({ theme }) => theme.gradients.main(235)};
  border-radius: 100%;
  z-index: -1;
`;

export default TopBlob;
