import React, { PropsWithChildren } from "react";
import styled from "styled-components";
import { StyledProps } from "types/StyledProps";
import MainElementWrapper from "./MainElementWrapper";
import TopBlob from "./visual/TopBlob";

interface SinglePageWrapperProps extends PropsWithChildren<StyledProps> {
  title?: React.ReactElement;
  mainElement?: React.ReactElement;
  centered?: boolean;
}

const SinglePageWrapper: React.FC<SinglePageWrapperProps> = (
  props: SinglePageWrapperProps
) => {
  return (
    <main className={props.className}>
      {props.mainElement ? (
        <>
          <TopBlob />
          <MainElementWrapper>{props.mainElement}</MainElementWrapper>
        </>
      ) : (
        <div style={{ height: "96px" }}></div>
      )}
      {props.title}
      {props.children}
    </main>
  );
};

const StyledSinglePageWrapper = styled(SinglePageWrapper)`
  position: relative;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: visible;
  text-align: center;
  min-height: 100vh;
  padding-bottom: 48px;
  ${({ centered, mainElement }) =>
    centered &&
    !mainElement &&
    `display: flex;
    align-items: center;
    justify-content: center;`}
`;

export default StyledSinglePageWrapper;
